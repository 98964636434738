import React, { Component } from "react";
import Sidebar from "../../components/Navigation/Sidebar";

export default class UnderDevelopment extends Component {
  render() {
    return (
      <div>
        {/* <!-- Page Wrapper --> */}
        <div id="wrapper">
          {/* <!-- Sidebar --> */}
          {/* <Sidebar /> */}
          <div className="container">
            <div className="card o-hnameden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                {/* Nested Row within Card Body */}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-red-200 mb-4">
                          <b  style={{ color: "red" }} >UNDER DEVELOPMENT</b>
                        </h1>
                      </div>

                      <div className="text-center">
                        <h1 className="h4 text-green-100 mb-4">
                          <b>Coming soon</b>
                        </h1>
                      </div>


                      <div className="text-center">
                        <a
                          className="small"
                          href="/clientdashboard"
                          style={{ color: "#1cc88a" }}
                        >
                          Go to client Dashboard
                        </a>
                      </div>
                      <div className="text-center">
                        <a
                          className="small"
                          href="/AdminDashboard"
                          style={{ color: "#1cc88a" }}
                        >
                          Go to Admin Dashboard
                        </a>
                      </div>
                      <div className="text-center">
                        <a
                          className="small"
                          href="/LandlordDashboard"
                          style={{ color: "#1cc88a" }}
                        >
                          Go to LandLord Dashboard
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
