// PropertyListPage.js
import React, { Component } from "react";
import Sidebar from "../../../../components/Navigation/Sidebar";
import Topbar from "../../../../components/Navigation/Topbar";
import PageHeading from "../../../../components/PageHeading";
import AddPropertyModal from "../../../../components/Admin/AddPropertyModal";
import ConfirmationModal from "../../../../components/Admin/DeleteConfrimationModal";
import propertyData from "../Data";
import EditPropertyModal from "../../../../components/Admin/EditPropertyModal";
class PropertyListPage extends Component {
  state = {
    properties: propertyData.properties,
    isAddPropertyModalOpen: false,
    isNewPropertyConfirmationOpen: false,
    isDeleteConfirmationOpen: false,
    isEditModalOpen: false,
    newProperty: {
      id: "",
      imageUrl: "",
      location: "",
      numberOfUnits: "",
      percentageOccupied: "",
      name: "",
    },
    propertyToDelete: null,
    propertyToEdit: null,
  };



  handleEdit = (id) => {
    // Open the edit modal for the selected property
    const propertyToEdit = this.state.properties.find((property) => property.id === id);
  
    this.setState({
      isEditModalOpen: true,
      propertyToEdit: propertyToEdit || null, // Set propertyToEdit to the found property or null if not found
    });
  };
  

  confirmEdit = () => {
    // Implement edit logic
    // For now, let's log the edited property to the console
    console.log("Edited Property:", this.state.propertyToEdit);

    // Close the edit modal
    this.setState({
      isEditModalOpen: false,
      propertyToEdit: null,
    });
  };

  cancelEdit = () => {
    // Cancel the edit operation
    this.setState({
      isEditModalOpen: false,
      propertyToEdit: null,
    });
  };

  handleDelete = (id) => {
    // Open the delete confirmation modal
    this.setState({
      isDeleteConfirmationOpen: true,
      propertyToDelete: id,
    });
  };


  confirmDelete = () => {
    // Implement delete logic
    const { propertyToDelete, properties } = this.state;
    const updatedProperties = properties.filter((property) => property.id !== propertyToDelete);

    this.setState({
      properties: updatedProperties,
      isDeleteConfirmationOpen: false,
      propertyToDelete: null,
    });
  };

  cancelDelete = () => {
    // Cancel the delete operation
    this.setState({
      isDeleteConfirmationOpen: false,
      propertyToDelete: null,
    });
  };

  handleAddProperty = () => {
    this.setState({
      isAddPropertyModalOpen: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isAddPropertyModalOpen: false,
      isNewPropertyConfirmationOpen: false,
      newProperty: {
        id: "",
        imageUrl: "",
        location: "",
        numberOfUnits: "",
        percentageOccupied: "",
        name: "",
      },
    });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      newProperty: {
        ...prevState.newProperty,
        [name]: value,
      },
    }));
  };

  handleAddPropertySubmit = (event) => {
    event.preventDefault();
    // Open the confirmation modal for adding a new property
    this.setState({
      isNewPropertyConfirmationOpen: true,
    });
  };

  confirmAddProperty = () => {
    // Implement logic to add the new property to the properties array
    const { properties, newProperty } = this.state;
    this.setState({
      properties: [...properties, newProperty],
      isAddPropertyModalOpen: false,
      isNewPropertyConfirmationOpen: false,
      newProperty: {
        id: "",
        imageUrl: "",
        location: "",
        numberOfUnits: "",
        percentageOccupied: "",
        name: "",
      },
    });
  };

  cancelAddProperty = () => {
    // Cancel the add property operation
    this.setState({
      isNewPropertyConfirmationOpen: false,
    });
  };
  handleEdit = (id) => {
    // Open the edit modal for the selected property
    const propertyToEdit = this.state.properties.find((property) => property.id === id);

    this.setState({
      isEditModalOpen: true,
      propertyToEdit,
    });
  };

  confirmEdit = () => {
    // Implement edit logic
    // For now, let's log the edited property to the console
    console.log("Edited Property:", this.state.propertyToEdit);

    // Close the edit modal
    this.setState({
      isEditModalOpen: false,
      propertyToEdit: null,
    });
  };

  cancelEdit = () => {
    // Cancel the edit operation
    this.setState({
      isEditModalOpen: false,
      propertyToEdit: null,
    });
  };

  render() {
    const {
      properties,
      isAddPropertyModalOpen,
      isNewPropertyConfirmationOpen,
      isDeleteConfirmationOpen,
      isEditModalOpen,
      newProperty,
      propertyToEdit,
    } = this.state;

    return (
      <div>
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topbar />
              <div className="container-fluid">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <PageHeading title="Properties" />
                  <button
                    className="btn btn-sm btn-outline-success"
                    onClick={this.handleAddProperty}
                  >
                    <i className="fas fa-plus"></i> Add Property
                  </button>
                </div>
                <div className="row">
                  {properties.map((property) => (
                    <div key={property.id} className="col-md-4 mb-4">
                      <div className="card">
                        <img
                          src={property.imageUrl}
                          className="card-img-top"
                          alt="Property Image"
                        />
                        <div className="card-body">
                          <h5 className="card-title">{property.name}</h5>
                          <p className="card-text">Location: {property.location}</p>
                          <p className="card-text">Number of Units: {property.numberOfUnits}</p>
                          <p className="card-text">Occupied: {property.percentageOccupied}%</p>
                        </div>
                        <div className="card-footer">
                          <div className="btn-group" role="group">
                            <button
                              className="btn btn-sm m-2 btn-outline-primary rounded "
                              type="button"
                              onClick={() => this.handleEdit(property.id)}
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-sm m-2 btn-outline-danger rounded"
                              type="button"
                              onClick={() => this.handleDelete(property.id)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
       

        <AddPropertyModal
          isOpen={isAddPropertyModalOpen}
          onClose={this.handleCloseModal}
          onSubmit={this.handleAddPropertySubmit}
          newProperty={newProperty}
          onInputChange={this.handleInputChange}
        />

        <ConfirmationModal
          isOpen={isNewPropertyConfirmationOpen}
          title="Confirm Add Property"
          message="Are you sure you want to add this property?"
          onConfirm={this.confirmAddProperty}
          onCancel={this.cancelAddProperty}
        />

        <ConfirmationModal
          isOpen={isDeleteConfirmationOpen}
          title="Confirm Delete"
          message="Are you sure you want to delete this property?"
          onConfirm={this.confirmDelete}
          onCancel={this.cancelDelete}
        />
        <EditPropertyModal
          isOpen={isEditModalOpen}
          onClose={this.cancelEdit}
          onSubmit={this.confirmEdit}
          newProperty={propertyToEdit} // Pass the property data to the modal for editing
          onInputChange={this.handleInputChange}
        />
       
      </div>
    );
  }
}

export default PropertyListPage;
