// Tenants.js

import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "../../../components/Navigation/Sidebar";
import Topbar from "../../../components/Navigation/Topbar";
import Footer from "../../../components/Navigation/Footer";
const Tenants = () => {
  const [filter, setFilter] = useState("");
//will modify ths to consume data from the main data json

  const tenantsData = [
    {
      id: 1,
      name: "John Doe",
      email: "john.doe@example.com",
      phone: 7837655473,
      idnumber:3874226,
      houseNumber: 101,
      faceImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsoEAMYKHiwI5JH_IlxayW3-9UurHlASFy9A&usqp=CAU",
      flatNumber: 201,
      buildingName: "Sovmatic Apartments",
      dateStarted: "2022-05-15",
    },
    {
      id: 2,
      name: "John Doe",
      email: "john.doe@example.com",
      phone: 7837655473,
      idnumber:3874226,
      houseNumber: 101,
      faceImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsoEAMYKHiwI5JH_IlxayW3-9UurHlASFy9A&usqp=CAU",
      flatNumber: 201,
      buildingName: "Sunset Residences",
      dateStarted: "2022-05-15",
    },
    {
      id: 3,
      name: "John Doe",
      email: "john.doe@example.com",
      phone: 7837655473,
      idnumber:3874226,
      houseNumber: 101,
      faceImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsoEAMYKHiwI5JH_IlxayW3-9UurHlASFy9A&usqp=CAU",
      flatNumber: 201,
      buildingName: "Yuwa Apartments",
      dateStarted: "2022-05-15",
    },
    {
      id: 4,
      name: "John Doe",
      email: "john.doe@example.com",
      phone: 7837655473,
      idnumber:3874226,
      houseNumber: 101,
      faceImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsoEAMYKHiwI5JH_IlxayW3-9UurHlASFy9A&usqp=CAU",
      flatNumber: 201,
      buildingName: "Oak Residency",
      dateStarted: "2022-05-15",
    },
    {
      id: 5,
      name: "John Bonga",
      email: "john.doe@example.com",
      phone: 7837655473,
      idnumber:3874226,
      houseNumber: 101,
      faceImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsoEAMYKHiwI5JH_IlxayW3-9UurHlASFy9A&usqp=CAU",
      flatNumber: 201,
      buildingName: "Oak Residency",
      dateStarted: "2022-05-15",
    },
    {
      id: 6,
      name: "John Bonga",
      email: "john.doe@example.com",
      phone: 7837655473,
      idnumber:3874226,
      houseNumber: 101,
      faceImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsoEAMYKHiwI5JH_IlxayW3-9UurHlASFy9A&usqp=CAU",
      flatNumber: 201,
      buildingName: "Oak Residency",
      dateStarted: "2022-05-15",
    },
    {
      id: 7,
      name: "Jojo Bonga",
      email: "john.doe@example.com",
      phone: 7837655473,
      idnumber:3874226,
      houseNumber: 101,
      faceImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsoEAMYKHiwI5JH_IlxayW3-9UurHlASFy9A&usqp=CAU",
      flatNumber: 201,
      buildingName: "Yuwa Apartments",
      dateStarted: "2022-05-15",
    },
    {
      id: 8,
      name: "Ted Bonga",
      email: "john.doe@example.com",
      phone: 7837655473,
      idnumber:3874226,
      houseNumber: 101,
      faceImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsoEAMYKHiwI5JH_IlxayW3-9UurHlASFy9A&usqp=CAU",
      flatNumber: 201,
      buildingName: "Sovmatics Apartments",
      dateStarted: "2022-05-15",
    },
    {
      id: 9,
      name: "Ken Reagan",
      email: "john.doe@example.com",
      phone: 7837655473,
      idnumber:3874226,
      houseNumber: 101,
      faceImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsoEAMYKHiwI5JH_IlxayW3-9UurHlASFy9A&usqp=CAU",
      flatNumber: 201,
      buildingName: "Oak Residency",
      dateStarted: "2022-05-15",
    },
    {
      id: 10,
      name: "Daniel Bonga",
      email: "john.doe@example.com",
      phone: 7837655473,
      idnumber:3874226,
      houseNumber: 101,
      faceImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsoEAMYKHiwI5JH_IlxayW3-9UurHlASFy9A&usqp=CAU",
      flatNumber: 201,
      buildingName: "Oak Residency",
      dateStarted: "2022-05-15",
    },
    // ... (previous dummy data)
  ];

  const filteredTenants = tenantsData.filter((tenant) =>
    tenant.name.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container mt-4">
              <div className="card mb-3">
                <div className="card-header">
                  <h5 className="mb-0">Tenants</h5>
                </div>
                <div className="card-body d-flex align-items-center">
                  {/* Search Bar */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Filter by name..."
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />

                  {/* Total Tenants Card */}

          
                  <div className="col-md-2 ml-3 d-flex align-items-center">
                    <div className="card bg-light">
                      <div className="card-body">
                        <h6 className="mb-0">
                          Total Tenants:{" "}
                          <span
                            className={`text-${
                              tenantsData.length > 0 ? "success" : "danger"
                            }`}
                          >
                            {tenantsData.length}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {filteredTenants.map((tenant) => (
                <div key={tenant.id} className="row mb-4">
                  <div className="col mx-auto">
                    <div className="card h-100">
                      <div className="d-flex justify-content-between align-items-center  text-white p-2"
                       style={{
                        backgroundColor: "rgb(0, 100, 0)",
                       
                      }}
                      >
                        <img
                          src={tenant.faceImage}
                          alt={tenant.name}
                          className="card-img-top"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }}
                        />
                        <div className="card-body m-2">
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="card-title">{tenant.name}</h5>
                            <div className="buttons-container d-flex ">
                              <button className="btn btn-primary btn-sm m-2">
                                Edit
                              </button>

                              <button className="btn btn-danger btn-sm  m-2">
                                Delete
                              </button>

                              <button className="btn btn-info btn-sm m-2">
                                Print
                              </button>
                            </div>
                          </div>

                          <div className="d-flex m-2">
                            <p className="card-text p-2">
                              <i className="fas fa-envelope"></i> :{" "}
                              {tenant.email}
                            </p>

                            <p className="card-text p-2">
                              {" "}
                              <i className="fas fa-id-card"></i> : {tenant.idnumber}
                            </p>

                            <p className="card-text p-2">
                              {" "}
                              <i className="fas fa-phone"></i> : {tenant.phone}
                            </p>
                          </div>
                  
                        </div>
                      </div>
                      <hr
                        className="mt-0 mb-3"
                        style={{ borderTop: "1px solid #333" }}
                      />
                      <div className="row m-2">
                        <div className="col-md-4">
                          <p className="card-text p-2">
                            <i className="fas fa-home"></i> Unit Number:{" "}
                            {tenant.flatNumber}
                          </p>
                        </div>
                        <div className="col-md-4">
                          <p className="card-text p-2">
                            <i className="fas fa-home"></i> Property Name:{" "}
                            {tenant.buildingName}
                          </p>
                        </div>
                        <div className="col-md-4">
                          <p className="card-text p-2">
                            <i className="fas fa-calendar"></i> Date Started:{" "}
                            {tenant.dateStarted}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Tenants;
