import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Pages
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import NotFound from "./pages/NotFound";
import Cards from "./pages/Admin/Cards";
import DataTableExample from "./pages/Admin/Tables"
import Houses from "./pages/Admin/Tables/Houses";
// import Payments from "./pages/Payments";
import AdminRegister from "./pages/Admin";
import Communications from "./pages/Admin/Communications";
import Addhouses from "./pages/Admin/Tables/Houses/Addhouses";
// import Invoices from "./pages/Invoices";
import PaymentReport from "./pages/Admin/Reports/Paymentreport";
// import RentalBalancesReport from "./pages/Admin/adminReports/RentalBalancesReport";
import Report from "./pages/Admin/Reports";
import Tenants from "./pages/Admin/Tenants";
import PropertyList from "./pages//Admin/Property/Properties";
import HousesList from "./pages/Admin/Tables/Houses/Houseslist";
import UnitsList from "./pages/Admin/Property/Units";
import Invoices from "./pages/Admin/Financials/Invoices";
import Payments from "./pages/Admin/Financials/Payments";
import TenantInvoices from "./pages/Tenant/Financials/Invoices";
import Pay from "./pages/Tenant/Financials/Pay";
import Transactions from "./pages/Tenant/Financials/Transactions";
import UnderDevelopment from "./pages/Underdevelopment";
import AdminDashboard from "./pages/Admin/Dashboard";
import ClientDashboard from "./pages/Tenant/Dashboard";
import LandlordDashboard from "./pages/Landlord/Dashboard";
const Routers = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<SignIn />} />

      <Route path="/transactions" element={<Transactions />} />
      <Route path="/LandlordDashboard" element={<LandlordDashboard />} />
      <Route path="/AdminDashboard" element={<AdminDashboard />} />
      <Route path="/ClientDashboard" element={<ClientDashboard />} />
      <Route path="/pay" element={<Pay />} />
      <Route path="/tenantinvoices" element={<TenantInvoices />} />
      <Route path="/underdevelopment" element={<UnderDevelopment />} />
      <Route path="/propertylist" element={<PropertyList />} />
      <Route path="/unitslist" element={<UnitsList />} />
     
      <Route path="/signup" element={<SignUp />} />
      <Route path="/cards" element={<Cards />} />
  
      <Route path="/datatable" element={<DataTableExample />} />
      <Route path="/adminRegister" element={<AdminRegister />} />
      <Route path="/houses" element={<Houses />} />
      <Route path="/tenants" element={<Tenants />} />
      <Route path="/payments" element={<Payments />} />
      <Route path="/Communications" element={<Communications />} />
      <Route path="/addhouses" element={<Addhouses />} />
      <Route path="/houseslist" element={<HousesList />} />
      <Route path="/invoices" element={<Invoices />} />
      <Route path="/reports" element={<Report />} />
      <Route path="/paymentreport" element={<PaymentReport />} />
      {/* <Route path="/rentalbalancesreport" element={<RentalBalancesReport />} /> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);

export default Routers;
