import React, { Component } from "react";

//Navigation
import ClientSidebar from "../../../components/Navigation/Sidebar/Client";
import Topbar from "../../../components/Navigation/Topbar";

import PageHeading from "../../../components/PageHeading";
import Footer from "../../../components/Navigation/Footer";

class ClientDashboard extends Component {
  componentWillMount() {
    document.getElementById("body").className = "page-top";
  }

  render() {
    return (
      <div>
        {/* <!-- Page Wrapper --> */}
        <div id="wrapper">
          {/* <!-- Sidebar --> */}
          <ClientSidebar />
          {/* <!-- End of Sidebar --> */}

          {/* <!-- Content Wrapper --> */}
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              {/* <!-- Topbar --> */}
              <Topbar />
              {/* <!-- End of Topbar --> */}

              {/* <!-- Begin Page Content --> */}
              <div className="container-fluid">
                {/* <!-- Page Heading --> */}

                {/* <PageHeading title="Tenant Dashboard   " /> */}
                <PageHeading title="Welcome back George Bonga" />

                <div class="col">
                  <div className="col mb-3">
                    <div className="card border-danger">
                      <div className="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2" style={{ color: "red" }}>
                            <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">
                              Balance Due
                            </div>
                            <div class="h5 mb-1 font-weight-bold">800</div>
                          </div>
                          <div class="col-auto">
                            <i
                              class="fas fa-file-invoice-dollar  "
                              style={{ color: "red" }}
                            ></i>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="col-md-12" >
                          <a
                            style={{  color: "darkgreen"}}
                            href="/pay"
                            className="d-flex justify-content-between font-weight-bold"
                          >
                            {" "}
                            <span     >Pay</span>{" "}
                            <span      className="fa fa-chevron-circle-right"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="sidebar-divider" />
                  <div className="col">
                    <div className="card border-dark">
                      <div className="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2" style={{ color: "black" }}>
                            <div class="h6 font-weight-bold text-danger text-uppercase">
                              Details
                            </div>
                            <div class="h5 mb-1 font-weight-bold">
                              George Bonga
                            </div>
                            <div class="h5 mb-1 font-weight-bold">
                              Master Apartments
                            </div>
                            <div class="h5 mb-1 font-weight-bold">Unit 309</div>
                            <div class="h5 mb-1 font-weight-bold">
                              Start date 5/8/2000
                            </div>
                            <div class="h5 mb-1 font-weight-bold">
                              <i class="fas  fa-phone"></i>

                              <span> +25479865985</span>
                            </div>
                            <div class="h5 mb-1 font-weight-bold ">
                              <i class="fas fa-envelope"></i>

                              <span> bongageorge17@gmail.com</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="col-md-12">
                          <a
                          style={{  color: "darkgreen"}}
                            href="/underdevelopment"
                            className="d-flex justify-content-between font-weight-bold"
                          >
                            {" "}
                            <span>More</span>{" "}
                            <span className="fa fa-chevron-circle-right"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="sidebar-divider" />
                  <div className="col">
                    <div className="card border-dark">
                      <div className="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2" style={{ color: "black" }}>
                            <div class="h6  font-weight-bold text-danger text-uppercase mb-1">
                              <span> Official Property Contacts</span>
                            </div>
                            <div class="h5 mb-1 font-weight-bold">
                              <i class="fas  fa-phone"></i>

                              <span> +25479865985</span>
                            </div>
                            <div class="h5 mb-1 font-weight-bold">
                              <i class="fas fa-envelope"></i>

                              <span> bongageorge17@gmail.com</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> 
                   <hr className="sidebar-divider" />


             
                </div>
                
              </div>
              {/* <!-- /.container-fluid --> */}
            </div>
            {/* <!-- End of Main Content --> */}

            {/* <!-- Footer --> */}

            <Footer />
            {/* <!-- End of Footer --> */}
          </div>
          {/* <!-- End of Content Wrapper --> */}
        </div>
        {/* <!-- End of Page Wrapper --> */}

        {/* <!-- Scroll to Top Button--> */}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
      </div>
    );
  }
}

export default ClientDashboard;
