
import React, { Component } from "react";
//Navigation
import Sidebar from "../../../components/Navigation/Sidebar/LandLord";
import Topbar from "../../../components/Navigation/Topbar";
import Footer from "../../../components/Navigation/Footer";

import Chart from 'chart.js/auto';

class LandlordDashboard extends Component {
  state = {
    selectedProperty: "Master", // Default selected property
    properties: [
      { name: "Master", totalTenants: 800, collectedAmount: 500, pendingAmount: 1200, totalRooms: 120, occupiedRooms: 70 },
      { name: "Master 2", totalTenants: 600, collectedAmount: 400, pendingAmount: 800, totalRooms: 80, occupiedRooms: 50 },
      { name: "Master 3", totalTenants: 1000, collectedAmount: 600, pendingAmount: 1500, totalRooms: 150, occupiedRooms: 90 },
    ],
    totalCollectedAmount: null,
    occupancyPercentage: null,
  };

  componentDidMount() {
    this.updateChart();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedProperty !== this.state.selectedProperty) {
      this.updateChart();
    }
  }

  updateChart() {
    const selectedPropertyData = this.state.properties.find(
      (prop) => prop.name === this.state.selectedProperty
    );
  
    // Get the existing Chart instance
    const existingChart = Chart.getChart(this.chartRef);
  
    // If the existing Chart instance exists, destroy it
    if (existingChart) {
      existingChart.destroy();
    }
  
    const ctx = document.getElementById("myChart").getContext("2d");
    const newChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["Collected", "Pending"],
        datasets: [
          {
            data: [
              selectedPropertyData.collectedAmount,
              selectedPropertyData.pendingAmount,
            ],
            backgroundColor: ["#3498db", "#e74c3c"],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
      },
    });
  
    // Save the reference to the newly created Chart instance
    this.chartRef = newChart;
  
    const totalCollectedAmount = this.state.properties.reduce(
      (total, prop) => total + prop.collectedAmount,
      0
    );
    const occupancyPercentage =
      (selectedPropertyData.occupiedRooms / selectedPropertyData.totalRooms) * 100;
    this.setState({ totalCollectedAmount, occupancyPercentage });
  }
  
  handlePropertyChange = (e) => {
    this.setState({ selectedProperty: e.target.value });
  };

  render() {
    const { selectedProperty, properties, totalCollectedAmount, occupancyPercentage } = this.state;
    const selectedPropertyData = properties.find(prop => prop.name === selectedProperty);
    

    return (
      <div>
        <div id="wrapper">
        <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
            <Topbar />
              <div className="container-fluid">
                <h1 className="h3 mb-4 text-gray-800">Landlord Dashboard</h1>

                <div className="row m-3">
                  <div className="col-md-8">
                    <div className="card shadow h-100 p-4">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col-md-4">
                            <div className="text-xs font-weight-bold text-dark text-uppercase mb-1">
                              Total Number of Tenants
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">
                              {selectedPropertyData.totalTenants}
                            </div>
                          </div>
                          <div className="col-md-4 text-center">
                            <div className="text-xs font-weight-bold text-dark text-uppercase mb-1">
                              Collected
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-primary">
                              Ksh {selectedPropertyData.collectedAmount}
                            </div>
                          </div>
                          <div className="col-md-4 text-center">
                            <div className="text-xs font-weight-bold text-dark text-uppercase mb-1">
                              Pending
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-danger">
                              Ksh {selectedPropertyData.pendingAmount}
                            </div>
                          </div>
                          <div className="col-md-12 mt-3">
                            <hr />
                          </div>
                          <div className="col-md-6 mt-3">
                            <p>
                            
                              Occupancy Level: {occupancyPercentage ? `${occupancyPercentage.toFixed(0)}%` : 'N/A'}
                            </p>
                          </div>
                          <div className="col-md-6 mt-3 text-center">
                            <canvas id="myChart"  width="100" height="100"></canvas>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card mt-4">
                      <div className="card-body">
                        <h5 className="card-title">Properties</h5>
                        <div className="form-group">
                          <label htmlFor="propertyDropdown">Select Property:</label>
                          <select
                            className="form-control"
                            id="propertyDropdown"
                            onChange={this.handlePropertyChange}
                            value={selectedProperty}
                          >
                            {properties.map(property => (
                              <option key={property.name} value={property.name}>
                                {property.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <h5 className="card-title">Total Collected Amount for All Properties</h5>
                        <div className="h5 mb-0 font-weight-bold text-primary">
                          Ksh {totalCollectedAmount || 'N/A'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LandlordDashboard;
