import React, { Component } from "react";
import Sidebar from "../../../components/Navigation/Sidebar";
import PageHeading from "../../../components/PageHeading";
import DataTable from "react-data-table-component";
import Topbar from "../../../components/Navigation/Topbar";

class Messages extends Component {
  constructor() {
    super();
    this.state = {
      pno: "", // State to store 'To' value
      msg: "", // State to store 'Message' value
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // Add your logic to handle the form submission here
    // You can access the 'pno' and 'msg' values from this.state
    // and send them to your server or perform any desired action.
  };

  render() {
    return (
      <div>
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <Topbar />
            <div className="container-fluid">
              <PageHeading title="Messages" />
              <div className="card shadow mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <div>
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                          <label>From:</label>
                          <div className=" bg-light p-2">
                            <span className="font-weight-bold text-primary">
                              George Bonga House 108 Dunga Apartments
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Heading:</label>
                          <input
                            type="text"
                            className="form-control form-control-user"
                            name="pno"
                            value={this.state.pno}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="form-group">
                          <label>Message Body:</label>
                          <textarea
                            className="form-control"
                            name="msg"
                            value={this.state.msg}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div>
                          <button
                            className="btn btn-success btn-user btn-lg"
                            type="submit"
                            name="smsg"
                          >
                            Send Message
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Messages;

// <form onSubmit={this.handleSubmit}>
//                         <div className="form-group">
//                           <label>From:</label>
//                           <div className="form-control form-control-user bg-light p-2">
//                             <span className="font-weight-bold text-primary">
//                               George Bonga House 108 Dunga Apartments
//                             </span>
//                           </div>
//                         </div>

//                         <div className="form-group">
//                           <label>Message:</label>
//                           <textarea
//                             className="form-control"
//                             name="msg"
//                             value={this.state.msg}
//                             onChange={this.handleInputChange}
//                           />
//                         </div>
//                         <div>
//                           <button
//                             className="btn btn-success btn-user btn-lg"
//                             type="submit"
//                             name="smsg"
//                           >
//                             Send Message
//                           </button>
//                         </div>
//                       </form>
