import React, { Component } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clickMenuOpen } from "../../../../redux/actions";

class ClientSidebar extends Component {
  componentDidMount() {
    document.getElementById("body").className = "page-top";
  }
  state = {
    sidebarToggled: false,
  };

  handleSideBarToggle() {
    if (this.sidebarToogled === true) {
      this.setState({ sidebarToggled: !this.state.sidebarToggled });
      document.getElementById("body").className = "page-top sidebar-toggled";
    } else {
      this.setState({ sidebarToggled: !this.state.sidebarToggled });
      document.getElementById("body").className = "page-top";
    }
  }

  render() {
    const { clickMenuOpen, toggled } = this.props;
    return (
      <>
        <ul
          style={{ backgroundColor: "darkgreen" }}
          className={
            toggled
              ? "navbar-nav  sidebar sidebar-dark accordion toggled"
              : "navbar-nav  sidebar sidebar-dark accordion"
          }
          id="accordionSidebar"
        >
          {/* <!-- Sidebar - Brand --> */}
          <a
            className="sidebar-brand d-flex align-items-center justify-content-center"
            href="index.html"
          >
            <div className="sidebar-brand-icon rotate-n-15">
              <i className="fas fa-home fa-cog"></i>
            </div>
            <div className="sidebar-brand-text mx-3">
              HOME<sup>s</sup>
            </div>
          </a>

          {/* <!-- Divider --> */}
          <hr className="sidebar-divider my-0" />

          {/* <!-- Nav Item - Dashboard --> */}
          <li className="nav-item">
            <Link className="nav-link" to="/clientdashboard">
              <i className="fas fa-home"></i>
              <span>Home</span>
            </Link>
          </li>

          {/* <!-- Divider --> */}

          <hr className="sidebar-divider" />
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#Finances"
              aria-expanded="true"
              aria-controls="Finances"
            >
              <i className="fas fa-dollar-sign"></i>
              <span>Financials</span>
            </a>
            <div
              id="Finances"
              class="collapse"
              aria-labelledby="Finances"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header"></h6>
                <a className="collapse-item" href="/tenantinvoices">
                  My Invoices
                </a>
                <a className="collapse-item" href="/transactions">
                  Transactions
                </a>

                <a
                  className="collapse-item"
                  style={{ color: "red", fontWeight: "bold" }}
                  href="pay"
                >
                  Pay Rent
                </a>
              </div>
            </div>
          </li>
          <hr className="sidebar-divider" />
          <li class="nav-item">
            <a class="nav-link" href="/pay">
              <i class="fas fa-fw fa-wrench"></i>
              <span>Maintainance</span>
            </a>
          </li>
          <hr className="sidebar-divider" />

          <li class="nav-item">
            <a class="nav-link" href="/underdevelopment">
              <i class="fas fa-fw fa-headset"></i>
              <span>Contact Support</span>
            </a>
          </li>
          <hr className="sidebar-divider" />
          {/* <!-- Sidebar Toggler (Sidebar) --> */}
          <div className="text-center d-none d-md-inline">
            <button
              onClick={() => {
                clickMenuOpen();
              }}
              className="rounded-circle border-0"
              id="sidebarToggle"
            ></button>
          </div>
        </ul>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ clickMenuOpen }, dispatch);

const mapStateToProps = (store) => ({
  toggled: store.menuState.menuOpen,
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientSidebar);
