import React, { Component } from "react";
import DataTable from "react-data-table-component";
import Sidebar from "../../../../components/Navigation/Sidebar";
import Topbar from "../../../../components/Navigation/Topbar";
import PageHeading from "../../../../components/PageHeading";
import propertyData from "../Data";
import AddUnitModal from "../../../../components/Admin/AddUnitModal";
import EditUnitModal from "../../../../components/Admin/EditUnitModal";
import ConfirmationModal from "../../../../components/Admin/DeleteConfrimationModal";
class UnitsList extends Component {
  columns = [
    {
      name: "Property",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Unit Number",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Occupied",
      selector: (row) => row.occupied,
      sortable: true,
    },
    {
      name: "Rent",
      selector: (row) => row.rent,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="dropdown">
          <button
            className="btn btn-outline-success dropdown-toggle"
            type="button"
            id={`dropdown-${row.id}`}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Actions
          </button>
          <div className="dropdown-menu" aria-labelledby={`dropdown-${row.id}`}>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => this.handleEdit(row)}
            >
              Edit
            </a>

            <a
              className="dropdown-item"
              href="#"
              onClick={() => this.handleDelete(row)}
            >
              Delete
            </a>
          </div>
        </div>
      ),
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      filteredData: propertyData.properties.flatMap((property) =>
        property.units.map((unit) => ({ ...property, ...unit }))
      ),
      isAddUnitModalOpen: false,
      newUnitData: {
        propertyId: "",
        unitId: "",
        type: "",
        occupied: "",
        rent: "",
        tenant: "",
      },
      isEditUnitModalOpen: false,
      selectedUnit: null,
    };
  }
  handleAddUnit = () => {
    this.setState({
      isAddUnitModalOpen: true,
    });
  };

  handleCloseAddUnitModal = () => {
    this.setState({
      isAddUnitModalOpen: false,
    });
  };

  handleAddUnitSubmit = () => {
    console.log("New Unit Data:", this.state.newUnitData);
    this.handleCloseAddUnitModal();
  };

  handleAddUnitInputChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      newUnitData: {
        ...prevState.newUnitData,
        [name]: value,
      },
    }));
  };

  handleEdit(row, props) {
    console.warn("Bonga");
    this.setState({
      isEditUnitModalOpen: true,
      isNewUnit: false,
      selectedUnit: row,
    });
  }

  handleCloseEditUnitModal = () => {
    this.setState({
      isEditUnitModalOpen: false,
      selectedUnit: null,
    });
  };

  handleDelete = (row) => {
   

    this.setState((prevState) => ({
      filteredData: prevState.filteredData.filter((item) => item.id !== row.id),
    }));
  };

  handleFilterChange = (e) => {
    const text = e.target.value.toLowerCase();

    this.setState((prevState) => ({
      filteredData: text
        ? prevState.filteredData.filter(
            (item) =>
              item.name.toLowerCase().includes(text) ||
              item.tenant.toLowerCase().includes(text) ||
              item.rentStatus.toLowerCase().includes(text) ||
              String(item.id).toLowerCase().includes(text)
          )
        : propertyData.properties.flatMap((property) =>
            property.units.map((unit) => ({ ...property, ...unit }))
          ),
    }));
  };

  render() {
    return (
      <div>
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topbar />
              <div className="container-fluid">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <PageHeading title="Units" />
                  <div>
                    <button
                      className="btn btn-sm btn-outline-success"
                      onClick={this.handleAddUnit}
                    >
                      <i className="fas fa-plus"></i> Add Unit
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <DataTable
                  title=""
                  columns={this.columns}
                  data={this.state.filteredData}
                  pagination
                  highlightOnHover
                  subHeader
                  subHeaderComponent={
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={this.handleFilterChange}
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, sans-serif",
                          fontWeight: "bold",
                          padding: 20,
                          margin: 10,
                        }}
                      />
                    </div>
                  }
                  customStyles={{
                    rows: {
                      style: {
                        fontSize: "16px",
                        fontFamily: "Arial, sans-serif",
                        fontWeight: "normal",
                        color: "gray",
                      },
                    },
                    headRow: {
                      style: {
                        fontSize: "17px",
                        fontFamily: "Arial, sans-serif",
                        fontWeight: "bold",
                      },
                    },
                    columns: {
                      style: {
                        fontSize: "14px",
                        fontFamily: "Arial, sans-serif",
                        fontWeight: "normal",
                        color: "yellow",
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <AddUnitModal
          isOpen={this.state.isAddUnitModalOpen}
          onClose={this.handleCloseAddUnitModal}
          propertyData={propertyData}
          onInputChange={this.handleAddUnitInputChange}
          onSubmit={this.handleAddUnitSubmit}
          formData={this.state.newUnitData}
        />
        {this.state.selectedUnit && (
          <EditUnitModal
            isOpen={this.state.isEditUnitModalOpen}
            onClose={this.handleCloseEditUnitModal}
            propertyData={propertyData}
            onInputChange={this.handleAddUnitInputChange}
            onSubmit={this.handleAddUnitSubmit}
            isNewUnit={this.state.isNewUnit}
            formData={this.state.selectedUnit}
          />
          
        )}
     
      </div>
    );
  }
}

export default UnitsList;
