import React from "react";

const EditUnitModal = ({ isOpen, onClose, onInputChange, onSubmit, formData, propertyData }) => {
  const { propertyId, unitId, type, occupied, rent } = formData;

  return (
    isOpen && (
      <div className="modal" tabIndex="-1" role="dialog" style={{ display: "block" }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Unit</h5>
              <button type="button" className="close" onClick={onClose} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={onSubmit}>
                <div className="form-group">
                  <label htmlFor="propertyId">Property</label>
                  {/* Replace this with a dropdown based on your propertyData */}
                  <input
                    type="text"
                    className="form-control"
                    id="propertyId"
                    name="propertyId"
                    value={propertyId}
                    onChange={onInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="unitId">Unit ID</label>
                  <input
                    type="text"
                    className="form-control"
                    id="unitId"
                    name="unitId"
                    value={unitId}
                    readOnly // Make the Unit ID field readonly since it's likely not editable
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="type">Type</label>
                  <input
                    type="text"
                    className="form-control"
                    id="type"
                    name="type"
                    value={type}
                    onChange={onInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="occupied">Occupied</label>
                  <input
                    type="text"
                    className="form-control"
                    id="occupied"
                    name="occupied"
                    value={occupied}
                    onChange={onInputChange}
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="tenant">rent</label>
                  <input
                    type="number"
                    className="form-control"
                    id="rent"
                    name="rent"
                    value={rent}
                    onChange={onInputChange}
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Save Changes
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default EditUnitModal;
