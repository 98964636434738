import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';

const PdfTable = () => {
  const [pdfFiles, setPdfFiles] = useState([
    { id: 1, name: 'JANUARY2024REPORT.pdf', url: 'path/to/sample1.pdf' },
    { id: 2, name: 'FEBRUARY2024REPORT.pdf', url: 'path/to/sample2.pdf' },
    // Add more PDF files as needed
  ]);

  const downloadPdf = (url, name) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) => console.error('Error downloading PDF:', error));
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>NO:</th>
          <th>File Name</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {pdfFiles.map((file) => (
          <tr key={file.id}>
            <td>{file.id}</td>
            <td>{file.name}</td>
            <td>
              <Button variant="success" onClick={() => downloadPdf(file.url, file.name)}>
                Download
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default PdfTable;
