import React from "react";

const AddUnitModal = ({ isOpen, onClose, onInputChange, onSubmit, formData, propertyData }) => {
  const { propertyId, unitId, type, occupied, rent } = formData;

  return (
    isOpen && (
      <div className="modal" tabIndex="-1" role="dialog" style={{ display: "block" }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Unit</h5>
              <button type="button" className="close" onClick={onClose} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={onSubmit}>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="propertyId">Property</label>
                    <select
                      className="form-control"
                      id="propertyId"
                      name="propertyId"
                      value={propertyId}
                      onChange={onInputChange}
                    >
                      <option value="" disabled>Select Property</option>
                      {propertyData && propertyData.properties
                        ? propertyData.properties.map((property) => (
                            <option key={property.id} value={property.id}>
                              {property.name}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="unitId">Unit ID</label>
                    <input
                      type="text"
                      className="form-control"
                      id="unitId"
                      name="unitId"
                      value={unitId}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="type">Type</label>
                    <input
                      type="text"
                      className="form-control"
                      id="type"
                      name="type"
                      value={type}
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="occupied">Occupied</label>
                    <input
                      type="text"
                      className="form-control"
                      id="occupied"
                      name="occupied"
                      value={occupied}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="rent">Rent</label>
                  <input
                    type="number"
                    className="form-control"
                    id="rent"
                    name="rent"
                    value={rent}
                    onChange={onInputChange}
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Add Unit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default AddUnitModal;
