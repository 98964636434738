import React, { Component } from "react";
import ClientSidebar from "../../../../components/Navigation/Sidebar/Client";

export default class Pay extends Component {
  render() {
    return (
      <div>
        {/* <!-- Page Wrapper --> */}
        <div id="wrapper">
          {/* <!-- Sidebar --> */}
          <ClientSidebar />
          <div className="container">
            <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="text-center">
                        <h1 className="h4 text-green-100 mb-4">
                          <b>Amount Due</b>
                        </h1>
                        <p>KES:  50000</p>
                      </div>
              <div className="card-body p-0">
                {/* Nested Row within Card Body */}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-red-200 mb-4">
                          <b style={{ color: "red" }}>PAYMENT OPTIONS</b>
                        </h1>
                      </div>

                      {/* Payment Option 1: Credit Card */}
                      <div className="text-center">
                        <h1 className="h4 text-green-100 mb-4">
                          <b>Credit Card</b>
                        </h1>
                        <p>Enter your credit card details and proceed with the payment.</p>
                      </div>

                      {/* Payment Option 2: PayPal */}
                      <div className="text-center">
                        <h1 className="h4 text-green-100 mb-4">
                          <b>PayPal</b>
                        </h1>
                        <p>Login to your PayPal account and complete the payment.</p>
                      </div>

                      {/* Payment Option 3: Bank Transfer */}
                      <div className="text-center">
                        <h1 className="h4 text-green-100 mb-4">
                          <b>Bank Transfer</b>
                        </h1>
                        <p>Transfer the amount to the provided bank account details.</p>
                      </div>

                      {/* Additional Payment Options can be added here */}

                      <div className="text-center">
                        <a
                          className="small"
                          href="/clientdashboard"
                          style={{ color: "#1cc88a" }}
                        >
                          Go to Client Dashboard
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
