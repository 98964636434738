import React, { Component } from 'react';

class OverduePayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Overdue Rental Payments',
      overduePayments: [
        {
          tenantName: 'John Doe',
          property: 'Nula Apartments',
          tenanthouse: '307G',
          paymentAmount: 500,
          dueDate: '2023-01-15',
        },
        {
          tenantName: 'Jane Smith',
          property: 'Nula Apartments',
          tenanthouse: '107G',
          paymentAmount: 600,
          dueDate: '2023-01-10',
        },
        {
          tenantName: 'Jane Smith',
          property: 'Bonga Apartments',
          tenanthouse: '107ki',
          paymentAmount: 600,
          dueDate: '2023-11-11',
        },
        // Add more overdue payments as needed
      ],
    };
  }

  calculateDaysLate(dueDate) {
    const currentDate = new Date();
    const dueDateObj = new Date(dueDate);
    const timeDiff = currentDate - dueDateObj;
    const daysLate = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysLate > 0 ? daysLate : 0;
  }

  handleActionChange(index, selectedAction) {
    switch (selectedAction) {
      case 'edit':
        // Navigate to the "/messages" page when "Edit" is selected
        window.location.href = '/messages';
        break;
      case 'sendReminder':
        // Implement logic for "Send Reminder"
        window.location.href = '/signup';
        break;
      case 'delete':
        // Implement logic for "Delete"
        window.location.href = '/signin';
        break;
      default:
        break;
    }
  }
  render() {
    return (
      <div className="card shadow mb-4">
        <a
          href="#collapseCardExample"
          className="d-block card-header py-3"
          data-toggle="collapse"
          role="button"
          aria-expanded="true"
          aria-controls="collapseCardExample"
        >
          <h6 className="m-0 font-weight-bold text-danger">{this.state.title}</h6>
        </a>
        <div className="collapse show" id="collapseCardExample">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Tenant Name</th>
                    <th>Property</th>
                    <th>Unit</th>
                    <th>Payment Amount</th>
                    <th>Due Date</th>
                    <th>Days late</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.overduePayments.map((payment, index) => (
                    <tr key={index}>
                      <td>{payment.tenantName}</td>
                      <td>{payment.property}</td>
                      <td>{payment.tenanthouse}</td>
                      <td className="text-danger">KES {payment.paymentAmount}</td>
                      <td>{payment.dueDate}</td>
                      <td className="text-danger">{this.calculateDaysLate(payment.dueDate)}</td>
                      <td>
                        <select
                          className="form-control"
                          onChange={(e) => this.handleActionChange(index, e.target.value)}
                        >
                          <option value="" disabled selected>
                            Action
                          </option>
                          <option value="edit">Edit</option>
                          <option value="sendReminder">Send Reminder</option>
                          <option value="delete">Confirm Payment</option>
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OverduePayments;
