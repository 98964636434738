const propertyData = {
    properties: [
      {
        id: 1,
        imageUrl: "https://i.pinimg.com/564x/2e/9a/fc/2e9afca78aa61919b24a36e55e674d1d.jpg",
        location: "Sovmatic Apartments",
        numberOfUnits: 10,
        percentageOccupied: 70,
        name: "Sovmatic Apartments",
        units: [
          { id: 1, type: "2-bedroom", occupied: "Yes", rent: 20000, tax: "George Bonga", rentStatus: "Pending" },
          { id: 2, type: "1-bedroom", occupied: "No", rent: 15000, tenant: "Alice Johnson", rentStatus: "Paid" },
          // Add other units as needed
        ],
      },
      {
        id: 2,
        imageUrl: "https://i.pinimg.com/564x/2e/9a/fc/2e9afca78aa61919b24a36e55e674d1d.jpg",
        location: "Another Property",
        numberOfUnits: 100,
        percentageOccupied: 10,
        name: "Another Property",
        units: [
          { id: 51, type: "2-bedroom", occupied: "Yes", rent: 20000, tenant: "George Bonga", rentStatus: "Pending" },
          { id: 52, type: "1-bedroom", occupied: "No", rent: 15000, tenant: "Alice Johnson", rentStatus: "Paid" },
          // Add other units as needed
        ],
      },
      {
        id: 3,
        imageUrl: "https://i.pinimg.com/564x/2e/9a/fc/2e9afca78aa61919b24a36e55e674d1d.jpg",
        location: "Master Bungalow",
        numberOfUnits: 5,
        percentageOccupied: 50,
        name: "Master Bungalow",
        units: [
          { id: 11, type: "2-bedroom", occupied: "Yes", rent: 18000, tenant: "John Doe", rentStatus: "Pending" },
          { id: 12, type: "studio",  occupied: "Yes", rent: 16000, tenant: "Jane Doe", rentStatus: "Paid" },
          // Add other units as needed
        ],
      },
      {
        id: 4,
        imageUrl: "https://i.pinimg.com/564x/2e/9a/fc/2e9afca78aa61919b24a36e55e674d1d.jpg",
        location: "Yuwa Apartments",
        numberOfUnits: 1000,
        percentageOccupied: 100,
        name: "Yuwa Apartments",
        units: [
          { id: 21, type: "2-bedroom",  occupied: "Yes", rent: 22000, tenant: "Mark Smith", rentStatus: "Pending" },
          { id: 22, type: "studio",  occupied: "No", rent: 20000, tenant: "Emily Johnson", rentStatus: "Paid" },
          // Add other units as needed
        ],
      },
      {
        id: 5,
        imageUrl: "https://i.pinimg.com/564x/2e/9a/fc/2e9afca78aa61919b24a36e55e674d1d.jpg",
        location: "Sunset Residences",
        numberOfUnits: 20,
        percentageOccupied: 90,
        name: "Sunset Residences",
        units: [
          { id: 31, type: "1-bedroom", occupied: "Yes", rent: 17000, tenant: "Chris Johnson", rentStatus: "Pending" },
          { id: 32, type: "3-bedroom", occupied: "Yes", rent: 25000, tenant: "Laura Smith", rentStatus: "Paid" },
          // Add other units as needed
        ],
      },
      // Add more properties as needed
    ],
  };
  
  export default propertyData;
  