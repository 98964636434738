import React, { useState } from "react";
import Topbar from "../../../../components/Navigation/Topbar";
import ClientSidebar from "../../../../components/Navigation/Sidebar/Client";
import PageHeading from "../../../../components/PageHeading";
const Transactions = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  return (
    <div>
      <div id="wrapper">
        <ClientSidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <PageHeading title="Transactions" />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-1 pb-1 border-bottom row">
                   
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="my-2 card">
                        <div className="d-flex h5 bg-white  card-header">
                          <span style={{ color: "rgb(0, 100, 0)" }}>
                            Summary
                          </span>
                        
                        </div>
                        <div className="collapse show">
                          <div className="card-body">
                            <div class="hr-text hr-text-center my-2">
                              <span id="Total-header" class="h5">
                                Total Amount
                              </span>
                            </div>
                            <div class="col-12">
                              <h2
                                id="Total-body"
                                class="mt-2 mb-0 text-center"
                                style={{ color: "rgb(0, 100,0)" }}
                              >
                                20,000.00
                              </h2>
                              <p
                                id="Total-footer"
                                class="mt-2 mb-0 text-center"
                              >
                                (KES)
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-2 card">
                        <div className="d-flex h5 bg-white  card-header">
                          <span style={{ color: "rgb(0, 100, 0)" }}>
                          Transactions
                          </span>
                        
                        </div>
                        <div className="collapse show">
                          <div className="pt-0 m-2 card-body">
                        
                            <div className="react-bootstrap-table mt-3">
                              <table
                                id="paymentsTable"
                                className="table table-responsive"
                              >
                                <thead>
                                  <tr>
                                    <th
                                      className="selection-cell-header"
                                      data-row-selection="true"
                                    >
                                      <div className="custom-control-empty custom-checkbox custom-control">
                                        <input
                                          id="headerSelectCheckbox"
                                          type="checkbox"
                                          className="custom-control-input"
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="headerSelectCheckbox"
                                        ></label>
                                      </div>
                                    </th>
                                    <th
                                      tabIndex="0"
                                      aria-label="Date sortable"
                                      className="sortable"
                                    >
                                      Date
                                      <span className="order">
                                        <span className="dropdown">
                                          <span className="caret"></span>
                                        </span>
                                        <span className="dropup">
                                          <span className="caret"></span>
                                        </span>
                                      </span>
                                    </th>
                                    <th tabIndex="0">Invoice ID/Number</th>
                                    <th tabIndex="0">Tenant</th>
                                    <th tabIndex="0">Item</th>
                                    <th tabIndex="0">Property (Unit)</th>
                                    <th tabIndex="0">Status</th>
                                    <th tabIndex="0">Amount</th>
                                    <th tabIndex="0">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="selection-cell">
                                      <div className="custom-control-empty custom-checkbox custom-control">
                                        <input
                                          id="headerSelect"
                                          type="checkbox"
                                          className="custom-control-input"
                                        />
                                        <label
                                          className="custom-control-label "
                                          htmlFor="headerSelect"
                                        ></label>
                                      </div>
                                    </td>
                                    <td>2023-11-17</td>
                                    <td>
                                      <a
                                        style={{
                                          textDecorationLine: "underline",
                                          color: "darkgreen",
                                        }}
                                      >
                                        36143
                                      </a>
                                    </td>
                                    <td>
                                      <span>Master Less</span>
                                    </td>
                                    <td>rent</td>
                                    <td>Master Apartments (001)</td>
                                    <td>open</td>
                                    <td>20000</td>
                                    <td>
                                     
                                      <select
                                        type="action"
                                        name="invoice-action"
                                        id="invoice-action36143"
                                        className="custom-select"
                                        style={{
                                          width: "90px",
                                          borderColor: "darkgreen",
                                        }}
                                      >
                                        <option value="">Action</option>
                                        
                                        <option value="36143">
                                         Raise Complaint
                                        </option>
                                        <option value="36143">
                                          Download Tansaction
                                        </option>
                                       
                                      </select>
                                    </td>
                                  </tr>
                                  
                                </tbody>
                                
                              </table>
                              <div className="row react-bootstrap-table-pagination">
                                <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6"></div>
                                <div
                                  paginationsize="3"
                                  className="d-flex col-md-6"
                                >
                                  <nav
                                    className="ml-md-auto mt-2 mt-md-0"
                                    aria-label="Page navigation example"
                                  >
                                    <ul
                                      paginationsize="3"
                                      className="my-0 pagination pagination-md"
                                    >
                                      <li className="page-item active">
                                        <button className="page-link " style={{
                                          backgroundColor: "darkgreen",
                                          borderColor: "darkgreen",
                                        }}>1</button>
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
