import React, { Component } from "react";
import Sidebar from "../../../../../components/Navigation/Sidebar";
import PageHeading from "../../../../../components/PageHeading";
import Topbar from "../../../../../components/Navigation/Topbar";

class Addhouses extends Component {
    constructor() {
        super();
        this.state = {
          name: '',
          compartment: 'Yes',
          cost: '50000',
        };
      }
    
      handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
      }
    
      handleSubmit = (event) => {
        event.preventDefault();
        // Add your logic to handle the form submission here
        // You can access the form data from this.state
      }
    

  render() {
    return (
      <div>
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <Topbar />
            <div className="container-fluid">
              <PageHeading title="Add House" />
              <div className="container-fluid">
        {/* <h1 className="h3 mb-2 text-gray-800" align="center">Add House</h1> */}
        <div className="card shadow mb-4">
          <div className="card-body">
            <div className="table-responsive">
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>House Name:</label>
                  <input
                    type="text"
                    className="form-control form-control-user"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Is there a compartment?</label>
                  <select
                    className="custom-select"
                    name="compartment"
                    value={this.state.compartment}
                    onChange={this.handleInputChange}
                    style={{ width: '300px' }}
                    required
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Cost of the House:</label>
                  <select
                    className="custom-select"
                    name="cost"
                    value={this.state.cost}
                    onChange={this.handleInputChange}
                    style={{ width: '300px' }}
                    required
                  >
                    <option value="50000">Ksh. 50,000/=</option>
                    <option value="60000">Ksh. 60,000/=</option>
                    <option value="70000">Ksh. 70,000/=</option>
                    <option value="80000">Ksh. 80,000/=</option>
                  </select>
                </div>
                <div>
                  <button className="btn btn-success btn-user btn-lg" type="submit" name="submit">
                    Add the House
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
        
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Addhouses;


