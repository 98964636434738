import React, { Component } from "react";
import Sidebar from "../../../../components/Navigation/Sidebar";
import Topbar from "../../../../components/Navigation/Topbar";
import PageHeading from "../../../../components/PageHeading";

class Houses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      houses: [
        {
          id: 1,
          house_no: "Sovmatic Apartments",
          category_id: 1,
          description: "Lorem ipsum dolor sit amet.",
          price: 150000,
          houseType: "Apartment",
          rooms: 3,
        },
        {
          id: 2,
          house_no: "Master Bungalow",
          category_id: 2,
          description: "Consectetur adipiscing elit.",
          price: 200000,
          houseType: "House",
        },
        // Add more dummy data as needed
      ],
      formData: {
        id: "",
        house_no: "",
        category_id: "",
        description: "",
        price: "",
        houseType: "Apartment",
        rooms: "",
      },
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { formData, houses } = this.state;

    // Add logic to handle form submission
    // Example: this.saveHouse(formData);
    // For now, let's just add a new house to the list for demonstration
    const newHouse = {
      ...formData,
      id: houses.length + 1, // Assign a new ID (replace with actual logic)
    };
    this.setState({
      houses: [...houses, newHouse],
      formData: {
        id: "",
        house_no: "",
        category_id: "",
        description: "",
        price: "",
        houseType: "Apartment",
        rooms: "",
      },
    });
  };

  handleEdit = (
    id,
    house_no,
    description,
    category_id,
    price,
    houseType,
    rooms
  ) => {
    this.setState({
      formData: {
        id,
        house_no,
        category_id,
        description,
        price,
        houseType,
        rooms,
      },
    });
  };

  handleDelete = (id) => {
    // Add logic to handle house deletion
    // Example: this.deleteHouse(id);
  };

  render() {
    const { houses, formData } = this.state;

    return (
      <div>
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topbar />
              <div className="container-fluid">
                <PageHeading title="Houses" />

              </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <form action="" onSubmit={this.handleSubmit}>
              <div className="card">
                <div className="card-header">House Form</div>
                <div className="card-body">
                  <div className="form-group">
                    <label className="control-label">House Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="house_no"
                      value={formData.house_no}
                      onChange={(e) =>
                        this.setState({
                          formData: { ...formData, house_no: e.target.value },
                        })
                      }
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label">House Type</label>
                    <select
                      className="custom-select"
                      name="houseType"
                      value={formData.houseType}
                      onChange={(e) =>
                        this.setState({
                          formData: { ...formData, houseType: e.target.value },
                        })
                      }
                      required
                    >
                      <option value="Apartment">Apartment</option>
                      <option value="House">House</option>
                    </select>
                  </div>
                  {formData.houseType === "Apartment" && (
                    <div className="form-group">
                      <label className="control-label">Number of Rooms</label>
                      <input
                        type="number"
                        className="form-control"
                        name="rooms"
                        value={formData.rooms}
                        onChange={(e) =>
                          this.setState({
                            formData: { ...formData, rooms: e.target.value },
                          })
                        }
                        required
                      />
                    </div>
                  )}
                  {/* Add this block for Price */}
                  <div className="form-group">
                    <label className="control-label">Price</label>
                    <input
                      type="number"
                      className="form-control"
                      name="price"
                      value={formData.price}
                      onChange={(e) =>
                        this.setState({
                          formData: { ...formData, price: e.target.value },
                        })
                      }
                      required
                    />
                  </div>
                  {/* ... Other form fields ... */}
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col-md-12">
                      <button className="btn btn-sm btn-primary col-sm-3 offset-md-3">
                        Save
                      </button>
                      <button
                        className="btn btn-sm btn-default col-sm-3"
                        type="reset"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="col-md-8">
            <div className="card">
              <div className="card-header">
                <b>House List</b>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th className="text-center">NO:</th>
                      <th className="text-center">House</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {houses.map((house, index) => (
                      <tr key={house.id}>
                        <td className="text-center">{index + 1}</td>
                        <td>
                          <p>
                            House Name: <b>{house.house_no}</b>
                          </p>
                          <p>
                            House Type: <b>{house.houseType}</b>
                          </p>
                          {house.houseType === "Apartment" && (
                            <p>
                              Number of Rooms: <b>{house.rooms}</b>
                            </p>
                          )}

                          {/* ... Display other details ... */}
                          <p>
                            Price : <b>Ksh {house.price}</b>
                          </p>
                        </td>
                        <td className="text-center">
                          <div className="btn-group" role="group">
                            <button
                              className="btn btn-sm btn-primary mr-2 rounded"
                              type="button"
                              onClick={() =>
                                this.handleEdit(
                                  house.id,
                                  house.house_no,
                                  house.description,
                                  house.category_id,
                                  house.price,
                                  house.houseType,
                                  house.rooms
                                )
                              }
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-sm btn-danger rounded"
                              type="button"
                              onClick={() => this.handleDelete(house.id)}
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
      </div>
      </div>
    );
  }
}

export default Houses;
