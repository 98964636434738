import React, { Component } from "react";
import Sidebar from "../../components/Navigation/Sidebar";

export default class AdminRegister extends Component {
  render() {
    return (
      <div>
        {/* <!-- Page Wrapper --> */}
        <div id="wrapper">
          {/* <!-- Sidebar --> */}
          <Sidebar />
          <div className="container">
            <div className="card o-hnameden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                {/* Nested Row within Card Body */}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">
                          <b>REGISTRATION</b>
                        </h1>
                      </div>

                      <form className="user" action="#" method="POST">
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <input
                              type="text"
                              className="form-control form-control-user"
                              name="Name"
                              placeholder="Name"
                              required
                            />
                          </div>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              className="form-control form-control-user"
                              name="uName"
                              placeholder="User Name"
                              required
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-sm-6">
                            <input
                              type="text"
                              className="form-control form-control-user"
                              name="pno1"
                              placeholder="Phone Number e.g; 254717*****"
                              required
                            />
                          </div>

                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <select
                              className="custom-select"
                              name="role"
                              id="durations"
                              style={{ width: "480px" }}
                              required
                            >
                              <option value="" disabled selected>
                                Role
                              </option>
                              <option value="Administrator">
                                Administrator
                              </option>
                              <option value="Manager">Manager</option>
                            </select>
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <input
                              type="password"
                              className="form-control form-control-user"
                              name="password"
                              placeholder="Password"
                              required
                            />
                          </div>
                          <div className="col-sm-6">
                            <input
                              type="password"
                              className="form-control form-control-user"
                              name="repeatPassword"
                              placeholder="Repeat Password"
                              required
                            />
                          </div>
                        </div>
                        <hr />
                        <center>
                          <input
                            className="btn btn-success btn-user btn-sm"
                            type="submit"
                            name="submit"
                            value="Register Account"
                          />
                        </center>
                      </form>

                      <div className="text-center">
                        <a
                          className="small"
                          href="/dashboard"
                          style={{ color: "#1cc88a" }}
                        >
                          Home
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
