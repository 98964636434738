import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import Sidebar from "../../../../components/Navigation/Sidebar";
class PrintablePaymentReport extends Component {
  render() {
    const { monthOf, payments } = this.props;

    const totalAmount = payments.reduce(
      (total, payment) => total + payment.amount,
      0
    );

    return (
      <div>
        <div className="on-print">
          <p>
            <center>Rental Payments Report</center>
          </p>
          <p>
            <center>
              for the Month of{" "}
              <b>
                {new Date(monthOf).toLocaleString("en-US", {
                  year: "numeric",
                  month: "long",
                })}
              </b>
            </center>
          </p>
        </div>
        <div className="row">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Tenant</th>
                <th>House #</th>
                <th>Invoice</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment, index) => (
                <tr key={payment.id}>
                  <td>{index + 1}</td>
                  <td>{new Date(payment.date_created).toLocaleDateString()}</td>
                  <td>{payment.tenant_name}</td>
                  <td>{payment.house_no}</td>
                  <td>{payment.invoice}</td>
                  <td className="text-right">{payment.amount.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan="5">Total Amount</th>
                <th className="text-right">{totalAmount.toFixed(2)}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  }
}

class PaymentReport extends Component {
  state = {
    monthOf: "2023-10", // Set the initial month
    payments: [
      {
        id: 1,
        date_created: "2023-10-05",
        tenant_name: "John Doe",
        house_no: "A101",
        invoice: "INV-001",
        amount: 1000.0,
      },
      {
        id: 2,
        date_created: "2023-10-10",
        tenant_name: "Jane Smith",
        house_no: "B202",
        invoice: "INV-002",
        amount: 1200.0,
      },
      // Add more payment data here
    ],
  };

  handleMonthChange = (e) => {
    this.setState({ monthOf: e.target.value });
  };

  render() {
    const { monthOf, payments } = this.state;

    return (
      <div id="wrapper">
        <Sidebar />

        <div className="container-fluid">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="col-md-12">
                  <div className="row form-group">
                    <label className="control-label col-md-2 offset-md-2 text-right">
                      Month of:
                    </label>
                    <input
                      type="month"
                      name="month_of"
                      className="form-control col-md-4"
                      value={monthOf}
                      onChange={this.handleMonthChange}
                    />
                    <button className="btn btn-sm btn-block btn-primary col-md-2 ml-1">
                      Filter
                    </button>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-12 mb-2">
                      <ReactToPrint
                        trigger={() => (
                          <button className="btn btn-sm btn-block btn-success col-md-2 ml-1 float-right">
                            <i className="fa fa-print"></i> Print
                          </button>
                        )}
                        content={() => this.componentRef}
                      />
                    </div>
                  </div>
                  <div id="report">
                    <PrintablePaymentReport
                      ref={(el) => (this.componentRef = el)}
                      monthOf={monthOf}
                      payments={payments}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentReport;
