import React, { Component } from "react";
import Chart from "chart.js/auto";

import CardBasic from "../../Cards/Basic";

class ChartBar extends Component {
  chartRef = React.createRef();
  state = {
    selectedYear: "2022",
  };
  chart = null;

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedYear !== this.state.selectedYear) {
      this.destroyChart(); // Destroy the existing chart
      this.renderChart();
    }
  }

  destroyChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }

  renderChart() {
    const myChartRef = this.chartRef.current.getContext("2d");

    const dataByYear = {
      2022: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Expenses",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 1,
            data: [
              5000, 6000, 4000, 7000, 3000, 8000, 6000, 9000, 7000, 10000, 8000,
              12000,
            ],
          },
          {
            label: "Payments",
            backgroundColor: "rgba(75, 192, 192, 0.5)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
            data: [
              3000, 7000, 5000, 8000, 6000, 9000, 7000, 11000, 9000, 12000,
              10000, 14000,
            ],
          },
          {
            label: "Invoices",
            backgroundColor: "rgba(54, 162, 235, 0.5)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
            data: [
              4000, 8000, 6000, 9000, 5000, 10000, 8000, 12000, 10000, 14000,
              12000, 16000,
            ],
          },
        ],
      },
      2023: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Expenses",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            borderColor: "rgba(255, 0, 0, 1)",
           
            data: [
              6000, 7000, 5000, 8000, 4000, 9000, 7000, 11000, 9000, 12000,
              10000, 14000,
            ],
          },
          {
            label: "Payments",
            backgroundColor: "rgba(75, 192, 192, 0.5)",
            borderColor: "rgba(0, 255, 0, 1)",
           
            data: [
              4000, 8000, 6000, 9000, 7000, 100, 8000, 12000, 10000, 14000,
              12000, 160,
            ],
          },
          {
            label: "Invoices",
            backgroundColor: "rgba(54, 162, 235, 0.5)",
            borderColor: "rgba(0, 0, 255, 1)",
           
            data: [
              5000, 6000, 4000, 7000, 600, 900, 7000, 11000, 9000, 12000,
              10000, 1400,
            ],
          },
        ],
      },
    };

    this.chart = new Chart(myChartRef, {
      type: "bar",
      data: dataByYear[this.state.selectedYear],
      options: {
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: false,
          },
          y: {
            stacked: false,
          },
        },
        legend: {
          display: true,
        },
      },
    });
  }

  handleYearChange = (event) => {
    this.setState({ selectedYear: event.target.value });
  };

  render() {
    return (
      <CardBasic title="Period Payments">
         <div class="form-group col-md-2">
            <label htmlFor="yearSelect">Select Year:</label>

            <select  id="yearSelect"
              className="form-control"
              value={this.state.selectedYear}
              onChange={this.handleYearChange}>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
            </select>
          </div>
        <div className="chart-area">
        
         
          <canvas id="myBarChart" ref={this.chartRef}></canvas>
        </div>
        <hr />
    
      </CardBasic>
    );
  }
}

export default ChartBar;
