import React, { Component } from 'react';

export default class Footer extends Component {
  constructor() {
    super();
    this.state = {
      currentYear: new Date().getFullYear(),
    };
  }

  render() {
    return (
      <div>
        <footer className="sticky-footer bg-white">
          <div className="container my-auto">
            <div className="copyright text-center my-auto">
              <span>Copyright &copy;{this.state.currentYear} SovMatics </span>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
