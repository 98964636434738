// EditPropertyModal.js
import React from "react";

const EditPropertyModal = ({ isOpen, onClose, onSubmit, editedProperty, onInputChange }) => {
  const propertyId = editedProperty ? editedProperty.id : "";
  const propertyName = editedProperty ? editedProperty.name : "";
  const propertyImageUrl = editedProperty ? editedProperty.imageUrl : "";
  const propertyLocation = editedProperty ? editedProperty.location : "";
  const propertyNumberOfUnits = editedProperty ? editedProperty.numberOfUnits : "";
  const propertyPercentageOccupied = editedProperty ? editedProperty.percentageOccupied : "";

  return (
    isOpen && (
      <div className="modal" tabIndex="-1" role="dialog" style={{ display: "block" }}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Property</h5>
              <button type="button" className="close" onClick={onClose} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={onSubmit}>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="id">ID</label>
                    <input
                      type="text"
                      className="form-control"
                      id="id"
                      name="id"
                      value={propertyId}
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={propertyName}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="imageUrl">Image URL</label>
                    <input
                      type="text"
                      className="form-control"
                      id="imageUrl"
                      name="imageUrl"
                      value={propertyImageUrl}
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="location">Location</label>
                    <input
                      type="text"
                      className="form-control"
                      id="location"
                      name="location"
                      value={propertyLocation}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="numberOfUnits">Number of Units</label>
                    <input
                      type="text"
                      className="form-control"
                      id="numberOfUnits"
                      name="numberOfUnits"
                      value={propertyNumberOfUnits}
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="percentageOccupied">Percentage Occupied</label>
                    <input
                      type="text"
                      className="form-control"
                      id="percentageOccupied"
                      name="percentageOccupied"
                      value={propertyPercentageOccupied}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">
                  Save Changes
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default EditPropertyModal;
