import React from "react";
import { Link, useNavigate } from "react-router-dom";
import homeImage from "../../assets/img/apartment.png";
const SignIn = () => {
  const navigate = useNavigate();

  const handleSignIn = () => {
    // Replace this line with the navigate function to go to the '/dashboard' route
    navigate("/AdminDashboard");
  };

  return (
    <div className="container">
      {/* <!-- Outer Row --> */}
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              {/* <!-- Nested Row within Card Body --> */}
              <div className="row">
                <div className="col-lg-6 d-none d-lg-block">
                  <img
                    src={homeImage}
                    alt="Home"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>

                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                    </div>
                    <form onSubmit={handleSignIn} className="user">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control form-control-user"
                          id="exampleInputEmail"
                          aria-describedby="emailHelp"
                          placeholder="Enter Email Address..."
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control form-control-user"
                          id="exampleInputPassword"
                          placeholder="Password"
                        />
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-checkbox small">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck"
                          >
                            Remember Me
                          </label>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-user btn-block"
                      >
                        Login
                      </button>
                    </form>
                    <hr />

                    <div className="text-center">
                      <a className="small" href="forgot-password.html">
                        Forgot Password?
                      </a>
                    </div>
                    <div className="text-center">
                      <Link className="small" to="/signup">
                        Create an Account!
                      </Link>
                    </div>


                    <div className="col bg-light round">

                    <div className="text-center ">
                      <Link className="big text-danger" to="/ClientDashboard">
                      TENANT DASHBOARD
                      </Link>
                    </div>
                    <div className="text-center">
                      <Link
                        className="small text-success"
                        to="/LandlordDashboard" 
                      >
                      LANDLORD DASHBOARD
                      </Link>
                    </div>
                    <div className="text-center ">
                      <Link
                        className="small text-danger"
                        to="https://mutable-web.web.app/"
                      >
                        WEBSITE
                      </Link>
                    </div>

                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
