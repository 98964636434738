import React, { Component } from "react";
import DataTable from "react-data-table-component";
import Sidebar from "../../../components/Navigation/Sidebar";
import Topbar from "../../../components/Navigation/Topbar";
import PageHeading from "../../../components/PageHeading";

const columns = [
  {
    name: "Name",
    selector: "name",
    sortable: true,
  },
  {
    name: "Occupation",
    selector: "occupation",
    sortable: true,
  },
  {
    name: "Phone Number",
    selector: "phone",
    sortable: true,
  },
  {
    name: "Email",
    selector: "email",
    sortable: true,
  },
  {
    name: "Region",
    selector: "region",
    sortable: true,
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
  },
];

const data = [
  {
    id: 1,
    name: "Tiger Nixon",
    occupation: "System Architect",
    phone: "123-456-7890",
    email: "tiger@example.com",
    region: "Edinburgh",
    status: "Active",
  },
  {
    id: 2,
    name: "Garrett Winters",
    occupation: "Accountant",
    phone: "987-654-3210",
    email: "garrett@example.com",
    region: "Tokyo",
    status: "Inactive",
  },
  {
    id: 3,
    name: "Ashton Cox",
    occupation: "Junior Technical Author",
    phone: "555-123-4567",
    email: "ashton@example.com",
    region: "San Francisco",
    status: "Active",
  },
  {
    id: 4,
    name: "Cedric Kelly",
    occupation: "Senior Javascript Developer",
    phone: "111-222-3333",
    email: "cedric@example.com",
    region: "Edinburgh",
    status: "Active",
  },
  {
    id: 5,
    name: "Airi Satou",
    occupation: "Accountant",
    phone: "999-888-7777",
    email: "airi@example.com",
    region: "Tokyo",
    status: "Inactive",
  },
  {
    id: 6,
    name: "Brielle Williamson",
    occupation: "Integration Specialist",
    phone: "777-666-5555",
    email: "brielle@example.com",
    region: "New York",
    status: "Active",
  },
  {
    id: 7,
    name: "Herrod Chandler",
    occupation: "Sales Assistant",
    phone: "444-555-6666",
    email: "herrod@example.com",
    region: "San Francisco",
    status: "Inactive",
  },
  {
    id: 8,
    name: "Rhona Davidson",
    occupation: "Integration Specialist",
    phone: "123-555-7890",
    email: "rhona@example.com",
    region: "Tokyo",
    status: "Active",
  },
  {
    id: 9,
    name: "Colleen Hurst",
    occupation: "Javascript Developer",
    phone: "111-222-3333",
    email: "colleen@example.com",
    region: "San Francisco",
    status: "Active",
  },
  {
    id: 10,
    name: "Sonya Frost",
    occupation: "Software Engineer",
    phone: "555-555-5555",
    email: "sonya@example.com",
    region: "Edinburgh",
    status: "Active",
  },
  {
    id: 11,
    name: "Sssonya Frost",
    occupation: "Software Engineer",
    phone: "555-555-5555",
    email: "sonya@example.com",
    region: "Edinburgh",
    status: "Active",
  },
  {
    id: 12,
    name: "Sssonya Frost",
    occupation: "Software Engineer",
    phone: "555-555-5555",
    email: "sonya@example.com",
    region: "Edinburgh",
    status: "Active",
  },
  // Add more data here...
];

class DataTableExample extends Component {
  constructor() {
    super();
    this.state = {
      filteredData: data,
    };
  }

  filteredItems(text) {
    return data.filter(
      (item) =>
        item.name.toLowerCase().includes(text) ||
        item.email.toLowerCase().includes(text) ||
        item.region.toLowerCase().includes(text)
    );
  }

  handleFilterChange = (e) => {
    this.setState({ filteredData: this.filteredItems(e.target.value) });
  };

  render() {
    return (
      <div>
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topbar />
              <div className="container-fluid">
                <PageHeading title="Tenants" />
           
              </div>
              <div className="card-body">
               
                <DataTable
                  title=""
                  columns={columns}
                  data={this.state.filteredData}
                  pagination
                  highlightOnHover
                  subHeader
                  subHeaderComponent={
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={this.handleFilterChange}
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, sans-serif",
                          fontWeight: "bold",
                          padding:20,
                          margin:10,
                        }}
                      />
                    </div>
                  }
                  customStyles={{
                    rows: {
                      style: {
                        fontSize: "16px", // Adjust the font size for data rows
                        fontFamily: "Arial, sans-serif", // Change the font family as needed
                        fontWeight: "normal", // Change the font weight as needed
                        color:"gray"
                      },
                    },
                    headRow: {
                      style: {
                        fontSize: "17px", // Adjust the font size for data rows
                        fontFamily: "Arial, sans-serif", // Change the font family as needed
                        fontWeight: "bold", // Change the font weight as needed
                      },
                    },
                    columns: {
                      style: {
                        fontSize: "14px", // Adjust the font size for data rows
                        fontFamily: "Arial, sans-serif", // Change the font family as needed
                        fontWeight: "normal", // Change the font weight as needed
                        color:"yellow"
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DataTableExample;



