import React, { Component } from "react";
import CardBasic from "../../../components/Cards/Basic";
import Sidebar from "../../../components/Navigation/Sidebar";
import PageHeading from "../../../components/PageHeading";
import Topbar from "../../../components/Navigation/Topbar";
import PdfTable from "../../../components/LandLord/Downloadpdf/PdfTable";
class Report extends Component {
  render() {
    return (
      <div>
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <Topbar />
            <div id="content" className="container-fluid">
              <div className="container d-flex justify-content-between align-items-center">
                <PageHeading title="Reports" />
              </div>
              <div className="container-fluid">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-sm-4 mb-3">
                            <div className="card border-success">
                              <div className="card-body">
                                <h4>
                                  <b>Monthly Payments Report</b>
                                </h4>
                              </div>
                              <div className="card-footer">
                                <div className="col-md-12">
                                  <a href="/paymentreport" className="d-flex justify-content-between text-success">
                                    {" "}
                                    <span>View Report</span>{" "}
                                    <span className="fa fa-chevron-circle-right"></span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 mb-3">
                            <div className="card border-success">
                              <div className="card-body">
                                <h4>
                                  <b>Rental Balances Report</b>
                                </h4>
                              </div>
                              <div className="card-footer">
                                <div className="col-md-12">
                                  <a href="/rentalbalancesreport" className="d-flex justify-content-between text-success">
                                    {" "}
                                    <span>View Report</span>{" "}
                                    <span className="fa fa-chevron-circle-right"></span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 mb-3">
                            <div className="card border-success">
                              <div className="card-body">
                                <h4>
                                  <b>Houses and Tenants Report</b>
                                </h4>
                              </div>
                              <div className="card-footer">
                                <div className="col-md-12">
                                  <a href="/messages" className="d-flex justify-content-between text-success">
                                    <span>View Report</span>
                                    <span className="fa fa-chevron-circle-right"></span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <PdfTable/>

                          {/* <div className="col-sm-4 mb-3">
                            <div className="card border-success">
                              <div className="card-body">
                                <h4>
                                  <b>Desert Lordess Report</b>
                                </h4>
                              </div>
                              <div className="card-footer">
                                <div className="col-md-12">
                                  <a href="/messages" className="d-flex justify-content-between">
                                    <span>View Report</span>
                                    <span className="fa fa-chevron-circle-right"></span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Report;
