import React, { Component } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clickMenuOpen } from "../../../../redux/actions";

class Sidebar extends Component {
  componentDidMount() {
    document.getElementById("body").className = "page-top";
  }

  state = {
    sidebarToggled: false,
  };

  handleSideBarToggle() {
    this.setState({ sidebarToggled: !this.state.sidebarToggled });

    const bodyClass = this.state.sidebarToggled
      ? "page-top sidebar-toggled"
      : "page-top";

    document.getElementById("body").className = bodyClass;
  }

  render() {
    const { clickMenuOpen, toggled } = this.props;

    return (
    
        <aside
          style={{  backgroundColor: "darkgreen"}}
          className={
            toggled
              ? "navbar-nav sidebar sidebar-dark accordion toggled flex flex-col overflow-y-auto"
              : "navbar-nav sidebar sidebar-dark accordion flex flex-col overflow-y-auto"
          }
          id="accordionSidebar"
        >
          {/* Brand */}
          <a
            className="sidebar-brand d-flex align-items-center justify-content-center"
            href="/AdminDashboard"
          >
            <div className="sidebar-brand-icon rotate-n-15">
              <i className="fas fa-home fa-cog"></i>
            </div>
            <div className="sidebar-brand-text mx-3">
              HOME<sup>s</sup>
            </div>
          </a>

          {/* Divider */}
          <hr className="sidebar-divider my-0" />

          {/* Dashboard */}
          <div className="nav-item">
            <Link className="nav-link" to="/landlorddashboard">
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </Link>
            {/* <Link className="nav-link text-danger" to="/clientdashboard">
              <span>*TenantDashboard*<sup>fordemo</sup></span>
            </Link> */}
          </div>

          {/* Divider */}
          <hr className="sidebar-divider" />

          {/* Property/Unit */}
          {/* <div className="nav-item">
            <a
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              <i className="fas fa-home fa-cog"></i>
              <span>Property/Unit</span>
            </a>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Details:</h6>
                <a className="collapse-item" href="/propertylist">
                  Properties
                </a>
                <a className="collapse-item" href="/unitslist">
                  Units
                </a>
                <a className="collapse-item" href="underdevelopment">
                  Maintenances
                </a>
              </div>
            </div>
          </div> */}

          {/* Divider */}
          {/* <hr className="sidebar-divider" /> */}

          {/* Tenants */}
          <div className="nav-item">
            <a className="nav-link" href="underdevelopment">
              <i className="fas fa-home"></i>
              <span>My Properties</span>
            </a>
          </div>

          {/* Divider */}
          <hr className="sidebar-divider" />

          {/* Financials */}
          {/* <div className="nav-item">
            <a
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#Finances"
              aria-expanded="true"
              aria-controls="Finances"
            >
              <i className="fas fa-dollar-sign"></i>
              <span>Financials</span>
            </a>
            <div
              id="Finances"
              className="collapse"
              aria-labelledby="Finances"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header"></h6>
                <a className="collapse-item" href="/invoices">
                  Invoices
                </a>
                <a className="collapse-item" href="/payments">
                  Payments
                </a>
                <a className="collapse-item" href="underdevelopment">
                  Expenses
                </a>
              </div>
            </div>
          </div> */}

          {/* Divider */}
          {/* <hr className="sidebar-divider" /> */}

          {/* Reports */}
          <div className="nav-item">
            <a className="nav-link" href="underdevelopment">
              <i className="fas fa-chart-bar"></i>
              <span>Reports</span>
            </a>
          </div>

          {/* Divider */}
          <hr className="sidebar-divider" />

          {/* Communications */}
          <div className="nav-item">
            <a className="nav-link" href="/underdevelopment">
              <i className="fas fa-fw fa-comments"></i>
              <span>Contact Manager</span>
            </a>
          </div>

          {/* Divider */}
          <hr className="sidebar-divider" />

          {/* Settings */}
          {/* <div className="nav-item">
            <a className="nav-link" href="/underdevelopment">
              <i className="fas fa-fw fa-cog"></i>
              <span>Settings</span>
            </a>
          </div> */}

          {/* Divider */}
          {/* <hr className="sidebar-divider" /> */}

          {/* Contact Support */}
          {/* <div className="nav-item">
            <a className="nav-link" href="/underdevelopment">
              <i className="fas fa-fw fa-headset"></i>
              <span>Contact Support</span>
            </a>
          </div> */}

          {/* Divider */}
          {/* <hr className="sidebar-divider" /> */}

          {/* Sidebar Toggler (Sidebar) */}
          <div className="text-center d-none d-md-inline">
            <button
              onClick={() => {
                clickMenuOpen();
              }}
              className="rounded-circle border-0"
              id="sidebarToggle"
            ></button>
          </div>
        </aside>
       
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ clickMenuOpen }, dispatch);

const mapStateToProps = (store) => ({
  toggled: store.menuState.menuOpen,
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
