import React, { Component } from 'react';
import Chart from 'chart.js/auto';

import CardBasic from '../../Cards/Basic';

class ChartDonut extends Component {
    chartRef = React.createRef();

    componentDidMount() {
        const myPieChart = this.chartRef.current.getContext('2d');

        new Chart(myPieChart, {
            type: 'doughnut',
            data: {
                labels: ['Empty', 'Occupied'],
                datasets: [
                    {
                        data: [15, 35],
                        backgroundColor: ['#4e73df', '#1cc88a'],
                        hoverBackgroundColor: ['#2e59d9', '#17a673'],
                        hoverBorderColor: 'rgba(234, 236, 244, 1)',
                    },
                ],
            },
            options: {
                maintainAspectRatio: false,
                tooltips: {
                    backgroundColor: 'rgb(255, 255, 255)',
                    bodyFontColor: '#858796',
                    borderColor: '#dddfeb',
                    borderWidth: 1,
                    xPadding: 15,
                    yPadding: 15,
                    displayColors: false,
                    caretPadding: 10,
                },
                legend: {
                    display: false,
                },
                cutoutPercentage: 80,
            },
        });
    }

    render() {
        const emptyHouses = 15;
        const occupiedHouses = 35;

        return (
            <CardBasic title="Occupancy">
                <div className="chart-pie pt-4">
                    <canvas id="myPieChart" ref={this.chartRef}></canvas>
                </div>
                <div className="text-center">
                    <div className="mt-4">
                        <p>Empty Units: {emptyHouses}</p>
                        <p>Occupied Units: {occupiedHouses}</p>
                    </div>
                </div>
                <hr />
             
              
            </CardBasic>
        );
    }
}

export default ChartDonut;
